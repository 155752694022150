
.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #000000;

    margin-bottom: 5px;
}

.container {
    width: 278px;
}
