@import "@common/styles/mixins";
@import "@common/styles/stylish-scrollbar";

.wrap {
  @include flex-columns-gap(8px);
  max-height: 500px;

  .scroll {
    overflow: auto;
    @include stylish-scrollbar();
  }
}
