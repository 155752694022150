.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #6b6b6b;
    opacity: 0.75;
    z-index: 100;
}
