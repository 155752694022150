.loader {
    border: 2px solid #f3f3f3;
    animation: spin 1s linear infinite;
    border-top: 2px solid #555;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
