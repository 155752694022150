
.sidebar {
    width: 250px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    /*border-right: 1px solid black;*/
    box-shadow: 1px 0 4px rgb(22 27 29 / 35%);
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    margin-bottom: 80px;
}

.sidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;

    text-align: center;
    font-size: 16px;
}

.sidebar-link,
.sidebar-help {
    font-size: 18px;
    font-weight: 500;
}

.active {
    font-weight: bold;
}

.sidebar-link:hover {
    text-decoration: underline;
}
.sidebar-help:hover {
    text-decoration: underline;
}

.disabled {
    pointer-events: none;
    user-select: none;
}
