.fields {
  .name {
    font-weight: 600;
  }

  .url {
    font-weight: 600;
  }

  .config {
    width: 400px;
    height: 400px;
  }
  .catch {
    width: 400px;
    height: 30px;

    &.filled {
      height: 400px;
    }
  }
}
