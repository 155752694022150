.picker {
  font-size: 14px;
  line-height: 14px;

  div:has(> i),
  li:has(> span > i),
  span:has(> i) {
    display: flex;
    align-items: center;

    i {
      display: inline-block;
      color: transparent;
      background-color: #ddd;
      border-radius: 16px;
      width: 12px;
      height: 12px;

      margin-top: 1px;

      &:hover {
        color: transparent;
        background-color: darken(#ddd, 10%);
      }
    }

    :global {
      .json-picked {
        background-color: #6A5;
      }
    }
  }
}