
.services-header{
    width: 100%;
    background-color: #FEE3AD;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #aaa;
}

.services-title{
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 30px;
}

.logo {
    color: black;
    font-size: 24px;
    font-weight: 300;
}

.services-search{
    border-radius: 50px;
    border: 1px solid red;
    padding: 5px;
}


.services-header-info{
    display: flex;
    gap: 20px;
}

.rate,
.profile{
    font-size: 18px;
    color: black;
    font-weight: 500;
}

.rate:hover{
    color: red;
}

.profile:hover{
    color: red;
}
