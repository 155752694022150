.container {
    display: flex;
}

.field-container {
    width: 350px;
}

.action-container {
    width: 110px;
}
