.container {
    position: fixed;
    z-index: 100;

    display: flex;
    flex-direction: column;
    gap: 2px;

    top: 10px;
    left: 0;
    right: 0;
    margin: auto;

    width: 600px;
}
