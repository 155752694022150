
.services-content-question{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.services-content-question-block{
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000;
    padding: 15px;
    row-gap: 15px;
}

.services-content-question-block span{
    font-size: 14px;
    font-weight: 500;
}

.services-content-question-block button{
    width: 100%;
    display: flex;
    height: 20px;
}

.services-content-integration{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}

.services-content-add{
    width: 100%;
    display: flex;
    justify-content: space-between;
    row-gap: 20px;
}

.services-content-item{
    width: 32%;
    height: 80px;
    border: 1px solid #000;
}

.services-search{
    border-radius: 50px;
    border: 1px solid red;
    padding: 5px;
}
