.container {

}

.row {
    margin-top: 10px;
}

.delimiter {
    margin: 10px 0;

    width: 100%;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: lightgray;
}

.add-button {
    margin-top: 10px;
}

.remove-button {
    margin-top: 5px;
    margin-bottom: 5px;
}

.bold {
    font-weight: 600;
}
