
.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;

    color: #000000;
}

.button {
    margin-top: 10px;
}

.fields {
    margin-top: 10px;

    width: 400px;
}
