@import "@common/styles/mixins";
@import "@common/styles/stylish-scrollbar";

.content {
  @include flex-columns-gap(8px);

  max-width: 80vh;
  max-height: 80vh;
  overflow: auto;
  @include stylish-scrollbar();

  border: 1px solid;
  padding: 12px;
  background-color: var(--bg);
}