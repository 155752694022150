.wrapper {
  select {
    width: 100%;

    box-shadow: inset 0 0 3px rgb(22 27 29 / 35%);
    border-radius: 10px;;
    outline: none;
    border-color: transparent;

    font-size: 18px;
    padding: 2px 0 2px 10px;

    appearance:none;
    cursor: pointer;

    option {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.singleline {
    position: relative;

    &::after {
      content: "▼";
      font-size: 12px;
      top: 8px;
      right: 12px;
      position: absolute;

      pointer-events: none;
    }

    select {
      padding: 2px 28px 2px 10px;
      height: 30px;
    }
  }
}
