.wrapper {
  input {
    background: #FFFFFF;

    width: 100%;
    height: 30px;

    box-shadow: inset 0 0 3px rgb(22 27 29 / 35%);
    outline: none;
    border-width: 1px;
    border-color: transparent;
    border-radius: 10px;;

    font-size: 18px;
    padding: 2px 10px;
  }

  &.transparent input {
    box-shadow: none;
    background: transparent;

    border-bottom: 1px dotted;
    border-radius: 0;
  }
}
