.name {
  font-weight: 600;
}

.container {
  & > * {
    margin-bottom: 4px;
  }

  p {
    overflow-wrap: break-word;
  }
}
