@mixin stylish-scrollbar {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    background: var(--bg);
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-thumb {
    background: #bed6e4;
    border-radius: 3px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  // Firefox
  scrollbar-color: #bed6e4 var(--bg);
  scrollbar-width: thin;
}

