.container {

}

.row {
}

.logic {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.delimiter {
    width: 100%;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: lightgray;

    margin: 10px 0;
}

.add-button {
    margin-top: 10px;
}

.remove-button {
    margin-top: 5px;
    margin-bottom: 5px;
}
