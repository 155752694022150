
.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;

  color: #000000;
}

.cardHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  color: #000000;
}

.buttons {
  margin-top: 10px;
}

.card {
  margin-top: 20px;

  width: 850px;
}

.step {
  margin: 10px 0;
}

.edit-container {
  width: 600px;
}

.save-block {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .checkbox {
    display: flex;
    align-items: center;
    margin-left: 12px;

    & > input {
      height: 24px;
      margin-right: 8px;
    }
  }
}
