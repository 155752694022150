.timerL, .timerR {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #fafafa;
    border-left-color: transparent;
    border-top-color: transparent;
    animation-iteration-count: infinite;
    animation-direction: reverse;
}

.timerL {
    animation-name: timer-slide;
    animation-timing-function: ease-in;
    left: 0;
}

.timerR {
    animation-name: timer-toggle;
    animation-timing-function: ease-out;
}

.timerSlot {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    overflow: hidden;
}

.pieTimer {
    width: 220px;
    height: 220px;
    border-radius: 200px;
    margin: 20px;

    position: relative;
    box-shadow: #333 0 0 1px;
    display: inline-block;
}

.pieTimer .timerL, .pieTimer .timerR {
    margin: -100px 0 0 -100px;
    border-width: 100px;

    height: 0;
    width: 0;
}

@keyframes timer-spin {
    0%   { transform: rotateY(540deg);}
    100% { transform: rotateY(0deg); }
}

@keyframes timer-slide {
    0% { transform: rotate(-225deg); }
    50% { transform: rotate(-45deg);  }
    100% { transform: rotate(-45deg);  }
}

@keyframes timer-toggle {
    0%  { opacity: 0; }
    50% { opacity: 0; }
    51% {
        transform: rotate(-45deg);
        opacity: 1;
    }
    75% {
        border-top-color: transparent;
    }
    76% {
        border-top-color: #fafafa;
    }
    100% {
        transform: rotate(137deg);
        opacity: 1;
        border-top-color: #fafafa;
    }
}
