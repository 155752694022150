
.body {
    display: flex;
    flex: auto;
    overflow-y: auto;

    background-color: var(--bg);
}

.services-content {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    padding: 15px 25px;
}
