
.wrapper {
    width: 100%;
    min-height: 100vh;
}

.main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.mainInner {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    background-color: white;
}
