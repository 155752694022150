.heading {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 52px;

    color: #000000;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}