.container {
  animation: appear 0.6s ease-in-out;

  color: #222;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 1px 1px 2px rgb(22 27 29 / 45%);
  padding: 5px 10px;
  text-align: center;
  line-height: 20px;

  display: flex;

  &.error {
    background-color: #FFBB73;
    border-color: indianred;
  }
  &.success {
    background-color: #BBFF73;
    border-color: greenyellow;
  }
}

.body {
  flex: auto;
}

.right {
}

.closer {
  position: relative;
  width: 18px;
  height: 18px;

  cursor: pointer;

  .timer {
    position: absolute;
    width: 18px;
  }

  .close {
    position: absolute;
    opacity: 0;

    svg {
      padding: 2px;
      width: 18px;
      height: 18px;
    }
  }

  &:hover .timer {
    opacity: 0;
  }

  &:hover .close {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
