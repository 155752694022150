@mixin hov($c) {
  &:hover {
    background-color: $c;
  }
}

@mixin act {
  &:active {
    box-shadow: 0 0 2px rgba(22, 27, 29, 0.73);
  }
}

.wrap {
  position: relative;

  .icon {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 4px;
    opacity: 0.75;

    padding: 2px;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: #6b6b6b;

    cursor: pointer;

    @include hov(#e5e5e5);
  }

  .disabled {
    input {
      color: initial;
    }

    .icon {
      border-color: transparent;

      @include hov(transparent);
    }
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;

    box-shadow: inset 0 0 3px rgb(22 27 29 / 35%);
    border-width: 1px;
    border-radius: 10px;

    background-color: ghostwhite;
    padding: 8px;
  }
}