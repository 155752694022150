.container {
  position: relative;
  display: inline-block;

  .loader {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.button {
  font-size: 14px;

  background: #fff;

  box-shadow: 1px 1px 1px rgba(22, 27, 29, 0.23);
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;

  padding: 4px 15px;

  transition: all 200ms ease;
  cursor: pointer;

  &:active {
    box-shadow: 1px 1px 4px rgba(22, 27, 29, 0.43);
  }

  &.neutral {
    border-color: #AAA;

    &:hover {
      background: #eee;
    }
  }

  &.positive {
    border-color: #37DD6F;

    &:hover {
      background: #63DD8D;
    }
  }

  &.negative {
    border-color: #FFA240;

    &:hover {
      background: #FFBB73;
    }
  }

  &.link {
    border-color: transparent;
    box-shadow: none;
    background: transparent;

    &:hover {
      text-decoration: underline;
    }
  }
}
