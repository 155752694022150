.wrap {
}

.header {
    display: flex;
    gap: 10px;
}

.header p {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
}

.controls {
    margin-top: 10px;
    gap: 5px;

    display: flex;
    justify-content: space-between;
}
.controls button {
    display: block;
}
